import { Box, CircularProgress, Stack, Typography } from "@mui/joy";

// import Swiper core and required modules
import { Autoplay, Navigation, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import BigCardItem from "../CardProductItem/BigCardItem";
import { useMemo, useState } from "react";
import GhostBlock from "../UI/GhostBlock";
import checkEmptyObject from "../../utils/utils";



function ProductListScroll({ productList, basket, setBasket, setObjModal, setCountBasketItem, addBasket, removeBasket }) {
    const [ anim, setAnim ] = useState(false);

    const list = useMemo(() => {
        // console.log('YYYYYYYY');
        // console.log(productList);
        if ( productList !== undefined )
            return productList.map((item, index) => 
                <SwiperSlide key={item?.mitm_ID} style={{ height: 'auto' }}>
                    <BigCardItem
                        setObjModal={setObjModal}
                        product={item}
                        basket={basket}
                        setBasket={setBasket}
                        addBasket={addBasket}
                        removeBasket={removeBasket}
                        />
                </SwiperSlide>
        )
        else {
            setAnim(true);
            return [{}]
        }
    }, [productList, basket])


    return (
        <Swiper
            style={{ padding: '20px 30px' }}
            modules={[Autoplay, Navigation, Scrollbar, A11y]}
            spaceBetween={20}
            slidesPerView={3}
            breakpoints={{
                0: {
                    slidesPerView: 1,
                },
                580: {
                    slidesPerView: 2,
                },
                880: {
                    slidesPerView: 2,
                },
                1150: {
                    slidesPerView: 3,
                },
            }}
            // navigation
            autoplay={{ delay: 2000, pauseOnMouseEnter: true }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
        >
            {
                checkEmptyObject(productList) ?
                <GhostBlock anim={anim} text={'К сожалению не удалось загрузить товары'} />
                :
                list.length < 1 ?
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ my: 2 }}
                >
                    <CircularProgress size="md" />
                </Stack>
                :
                list
            }
        </Swiper>
    )
}

export default ProductListScroll;
import { TilipadGetImageProduct } from "./tilipad";



// -=-=- Проверка что объекта что он = [{}] -=-=-
export default function checkEmptyObject (array) {
    // return obj.status === 'error'
    return array != undefined & array != null ? array.some(item => Object.keys(item).length === 0) : true
};

// -=-=- Создание разных токенов Например: aB3kD5Zx -=-=-=-
export function generateToken(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        token += characters[randomIndex];
    }

    return token;
}

const isEmpty = (obj) => Object.keys(obj).length === 0;
// -=-=- Подгрузка картинки -=-=-
export function loadImageProduct(product, setImage, menu, setMenu) {
    // Если картинка есть в подгруженнм меню
    // console.log(product?.image);
    if (product?.image) setImage(product?.image);
    // Если нет, то подгружаем из Tilipad
    else 
        TilipadGetImageProduct(product.mitm_ID).then(image => { 
            // console.log('IMAGE', image);
            // console.log('RRR', product.mitm_ID);
            if (image != undefined) {
                const listItems = menu?.MenuItems?.map(function (element, i) {
                    if (product.mitm_ID == element.mitm_ID) {
                        element.image = image;
                    }
                    return element;
                });
                setMenu({ MenuGroups: menu?.MenuGroups, MenuItems: listItems });
                setImage(image);
            }
            else setImage(image);
        });
}

export function validatePhoneNumber(phone) {
    const phoneRegex = /^(\+7|8)?\(\d{3}\)\d{3}-\d{2}-\d{2}$/; // Регулярное выражение для российского номера
    return phoneRegex.test(phone);
};

// Взять эмодзи провиля
export function getEmoji(phone) {
    if (phone) {
        if (phone.slice(-1) == 0) return '/emoji/cool.png'
        else if (phone.slice(-1) == 1) return '/emoji/crazy.png'
        else if (phone.slice(-1) == 2) return '/emoji/ghost.png'
        else if (phone.slice(-1) == 3) return '/emoji/love.png'
        else if (phone.slice(-1) == 4) return '/emoji/money.png'
        else if (phone.slice(-1) == 5) return '/emoji/party.png'
        else if (phone.slice(-1) == 6) return '/emoji/robot.png'
        else if (phone.slice(-1) == 7) return '/emoji/stars.png'
        else if (phone.slice(-1) == 8) return '/emoji/tasty.png'
        else if (phone.slice(-1) == 9) return '/emoji/alien.png'
    }
}
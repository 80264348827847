import { Avatar, Box, Button, DialogContent, DialogTitle, Grid, Input, ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";

import InputMask from 'react-input-mask';
import ItemOrder from "../ItemOrder";

import CakeIcon from '@mui/icons-material/Cake';
import { getEmoji } from "../../../utils/utils";
import { useContext, useEffect, useMemo, useState } from "react";
import { LayoutContext } from "../../context";
import { ChangeAccount, LogoutAccount } from "../../../utils/tilipad";
import SnackBar from "../../UI/SnackBar";
import Cookies from 'js-cookie';



export default function DialogAccount({ state, setOpen, setStage, status }) {
    const { user, setUser } = useContext(LayoutContext);
    
    const [ name, setName ] = useState(user?.name);
    const [ phone, setPhone ] = useState(user?.phone);
    const [ dateBirth, setDateBirth ] = useState(user?.date_birth);

    useEffect(() => {
        setName(user?.name);
        setPhone(user?.phone);
        setDateBirth(user?.date_birth);
    }, [user]);

    const [ loadSave, setLoadSave ] = useState(false);

    function formatDate(date, format) {
        return format.replace('mm', (date.getMonth() + 1) >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`)
                    .replace('yy', date.getFullYear())
                    .replace('dd', date.getDate());
    }

    // Выход из аккаунта
    function logoutUser() {
        LogoutAccount().then(data => {
            if (data?.status == 'ok') {
                setOpen(false);
                Cookies.remove('u_token');
                setTimeout(() => {
                    setUser({});
                    setStage(status.auth);
                    setOpen(true);
                }, 300);
            }
        })
    }

    const [ openSnackSuccess, setOpenSnackSuccess ] = useState(false);
    // Хэширвоание Снэкбара
    const snackBar = useMemo(() => {
        return <SnackBar
                    openSnackSuccess={openSnackSuccess}
                    setOpenSnackSuccess={setOpenSnackSuccess}
                >
                    <Stack spacing={0.5}>
                        <Typography sx={{ fontFamily: 'Nunito', fontWeight: 'bold' }} level="title-md">
                            Информация:
                        </Typography>
                        <Typography
                            sx={{ fontFamily: 'Nunito' }} 
                            startDecorator={ 
                                <img style={{ objectFit: 'cover', height: '100%', borderRadius: '15px', maxWidth: '100%' }} 
                            src="/check_order.gif" alt=""/> }>
                            Данные успешно сохранены
                        </Typography>
                    </Stack>
                </SnackBar>
    }, [openSnackSuccess])


    function changeSave() {
        setLoadSave(true);
        var trim_phone = phone.trim().replace(/\s+/g, '');
        const _data_user = {
            name: name,
            phone: trim_phone,
            date_birth: dateBirth
        }
        ChangeAccount(_data_user).then(data => {
            if (data?.status == 'ok') {
                setUser(data?.user);
                setOpenSnackSuccess(true);
            }
            setLoadSave(false);
        })
    }

    return (
        <ModalDialog
            sx={(theme) => ({
                [theme.breakpoints.up("xl")]: {
                    width: '600px',
                    mx: 0,
                },
                [theme.breakpoints.down("xl")]: {
                    minWidth: '45%',
                    mx: 0,
                },
                [theme.breakpoints.down("lg")]: {
                    width: '60%',
                    mx: 0,
                },
                [theme.breakpoints.down("md")]: {
                    width: '80%',
                    mx: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    width: '90%',
                    p: 3,
                },
                my: 5,
                p: 4,
                borderRadius: 'xl',
                opacity: 0,
                transition: `opacity 200ms`,
                ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                }[state],
            })}
        >
            <ModalClose style={{ zIndex: 2 }} />
            <DialogTitle>
                <Typography level="h2">Аккаунт</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid container sx={{ width: '100%' }} spacing={4} columns={{ xs: 4, sm: 2, md: 4 }}>
                        <Grid xs={4} sm="auto" md="auto" lg="auto" sx={{ p: { xs: 0, md: 2 }, width: { sm: '100%', md: 'auto' } }}>
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Avatar
                                    sx={{ p: 2.5, "--Avatar-size": "70px" }}
                                    alt="Аватар" src={getEmoji(user.phone)} size="lg" />
                                <Typography startDecorator={<CakeIcon color="danger"/>}>
                                    У вас {user?.point ? user?.point : 0} бонусов
                                </Typography>
                            </Stack>
                            
                        </Grid>
                        <Grid xs={4} sm={2} md sx={{ p: { xs: 0, md: 2 } }}>
                            <form>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ width: '100%' }}
                                >
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            Имя*
                                        </Typography>
                                        <Input sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} type="text" onChange={(e) => setName(e.target.value)} value={name} placeholder="Иван" variant="outlined" />
                                    </Box>
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            Номер телефона*
                                        </Typography>
                                        <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)} disabled>
                                            {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} type="tel" placeholder="+7 (000) 000-00-00" variant="outlined" disableUnderline />}
                                        </InputMask>
                                    </Box>
                                    
                                    <Box sx={{ mt: 2, width: '100%' }}>
                                        <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                            Дата рождения
                                        </Typography>
                                        <Input sx={{ borderRadius: 'md', boxShadow: 'none', borderColor: 'neutral.300' }} 
                                            slotProps={{
                                                input: {
                                                    max: formatDate(new Date(Date.now()), 'yy-mm-dd'),
                                                },
                                            }}
                                            type="date" variant="outlined" value={dateBirth} onChange={(e) => setDateBirth(e.target.value)} />
                                    </Box>
                                </Stack>
                                
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={1}
                                    sx={{ mt: 3 }}
                                >
                                    <Button 
                                        variant="outlined"
                                        sx={{ m: 0, fontSize: 'md' }}
                                        onClick={() => changeSave()}
                                        loading={loadSave}
                                    >
                                        Сохранить
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color='danger'
                                        sx={{ m: 0, fontSize: 'md' }}
                                        onClick={() => logoutUser()}>
                                            Выйти
                                    </Button>
                                </Stack>
                            </form>
                        </Grid>
                    </Grid>
                    
                    {/* { user?.orders & user?.orders != [] ?
                        <Box sx={{ width: '100%' }}>
                            <Typography level="h3" color="primary" sx={{ mb: 2 }}>Заказы</Typography>
                            <Box
                                sx={{ width: '100%', overflowY: 'scroll', height: '40vh' }}
                            >
                                {user?.orders?.map(itm => 
                                    <ItemOrder></ItemOrder>
                                )}
                            </Box>
                        </Box>
                        :
                        undefined
                    } */}
                </Stack>
            </DialogContent>
            {snackBar}
        </ModalDialog>
    )
}
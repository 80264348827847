import { Box, Button, Checkbox, DialogContent, DialogTitle, Input, Link, ModalClose, ModalDialog, Stack, Tab, tabClasses, TabList, Tabs, Typography } from '@mui/joy';

import InputMask from 'react-input-mask';
import React, { useState } from 'react';
import { RegistrationAccount } from '../../../utils/tilipad';
import { validatePhoneNumber } from '../../../utils/utils';
import {Link as LinkRouter} from "react-router-dom";


// -=-=- Модальное окно регистрации -=-=-
export default function DialogRegistration ({ state, setModalAccount, setStage, status, setTextModal, phone, setPhone }) {
    
    const [ loading, setLoading ] = useState(false);
    const [ name, setName ] = useState('');
    const [ checkPrivacy, setCheckPrivacy ] = useState(false);
    const [ error, setError ] = useState(undefined);

    // Состояние для отслеживания активной вкладки
    const [activeTab, setActiveTab] = useState(1);
    // Обработчик изменения вкладки
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    function registration() {
        var trim_name = name?.trim();
        if (trim_name == '') {
            setName('');
            alert('Вы не указали имя');
            return
        };
        var trim_phone = phone?.trim().replace(/\s+/g, '');
        if (!validatePhoneNumber(trim_phone)) {
            setPhone('');
            alert('Вы указали не корректный номер телефона');
            return
        };
        if (!checkPrivacy) {
            alert('Вы не согласились с политикой конфиденциальности');
            return
        };
        const _data = {
            name: name,
            phone: trim_phone
        };
        if (activeTab == 0) {
            _data.code_sms = true;
            setTextModal(`На номер ${trim_phone} отправлено смс с кодом. Сообщение может прийти в течении 2 мин.`);
        }
        else if (activeTab == 1) {
            _data.code_phone = true;
            setTextModal(`В течении 30 сек., на номер ${trim_phone} позвонит неизвестный абонен, необходимо ввести последние 4 цифры абонента. Отвечать на звонок не надо.`);
        }

        RegistrationAccount(_data).then(data => {
            if (data?.status) {
                setStage(status.code);
            } 
            else setError(data?.detail);
            setLoading(false);
        });
    }

    return (
        <ModalDialog
            sx={(theme) => ({
                [theme.breakpoints.up("xl")]: {
                    width: '400px',
                    mx: 0,
                },
                [theme.breakpoints.down("xl")]: {
                    minWidth: '30%',
                    mx: 0,
                },
                [theme.breakpoints.down("lg")]: {
                    width: '40%',
                    mx: 0,
                },
                [theme.breakpoints.down("md")]: {
                    width: '55%',
                    mx: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    width: '90%',
                    p: 3,
                },
                my: 5,
                p: 4,
                borderRadius: 'xl',
                opacity: 0,
                transition: `opacity 200ms`,
                ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                }[state],
            })}
        >
            <ModalClose style={{ zIndex: 2 }} />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <img width={100} style={{ filter: 'grayscale(1)', opacity: '0.3' }} src="../logo.png" />
                <DialogTitle>
                    <Typography level="h2">Регистрация</Typography>
                </DialogTitle>
            </Stack>
            <DialogContent>
                <form style={{ width: '100%', overflow: 'hidden' }}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Box sx={{ width: '100%' }}>
                            { error ?
                                <Typography sx={{ width: "100%" }} level='title-sm' color='danger'>
                                    {error}
                                </Typography>
                                :
                                undefined
                            }
                            <Box sx={{ mt: 1 }}>
                                <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                    Имя
                                </Typography>
                                <Input onChange={(e) => setName(e.target.value)} sx={{ borderRadius: 'md' }} type="text" placeholder="Иван" variant="outlined" disableUnderline />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                    Номер телефона
                                </Typography>
                                <InputMask mask="+7 (999) 999-99-99" onChange={(e) => setPhone(e.target.value)}>
                                    {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md' }} type="tel" placeholder="+7 (000) 000-00-00" variant="outlined" disableUnderline />}
                                </InputMask>
                            </Box>
                            <Stack direction="row"
                                spacing={1}
                                sx={{
                                    mt: 2,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Checkbox onChange={(e) => setCheckPrivacy(e.target.checked)}/>
                                <Typography level="body-sm">
                                    Я согласен с <LinkRouter onClick={() => setModalAccount(false)} to="/privacy/"><Link color="primary" sx={{ lineHeight: 1 }}>политикой конфиденциальности</Link></LinkRouter> сайта Tsunami
                                </Typography>
                            </Stack>
                        </Box>
                        {/* <Tabs
                            onChange={handleTabChange}
                            value={activeTab}
                            aria-label="tabs" 
                            defaultValue={0} 
                            sx={{ width: '100%', mt: 2, bgcolor: 'transparent' }}
                        >
                            <TabList
                                disableUnderline
                                sx={{
                                    width: '100%',
                                    p: 0.5,
                                    gap: 0.5,
                                    borderRadius: 'xl',
                                    bgcolor: 'background.level1',
                                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                        boxShadow: 'sm',
                                        bgcolor: 'background.surface',
                                    },
                                }}
                            >
                                <Tab disableIndicator value={0} sx={{
                                    width: '100%'
                                }}>
                                    Через SMS
                                </Tab>
                                <Tab disableIndicator value={1} sx={{
                                    width: '100%'
                                }}>
                                    Через звонок
                                </Tab>
                            </TabList>
                        </Tabs> */}
                        <Button
                            sx={{ fontSize: 'lg', width: '100%' }}
                            onClick={() => registration()}
                            loading={loading}
                        >
                            Регистрация
                        </Button>
                        <Typography>
                            <Link onClick={(e) => setStage(status.auth)}>Авторизация</Link>
                        </Typography>
                    </Stack>
                </form>
            </DialogContent>
        </ModalDialog>
    )
}

import { Box, CircularProgress, Grid, Stack } from "@mui/joy";
import LinkCardMenuItem from "./CardMenuItem/LinkCardMenuItem";
import { useMemo, useState } from "react";
import GhostBlock from "../UI/GhostBlock";



export default function MenuCardList({ menuList }) {
    const [ anim, setAnim ] = useState(false);

    const menu = useMemo(() => {
        // console.log('FFFFFFFF');
        // console.log(menuList)
        if (menuList == undefined) setAnim(true);
        return menuList;
    }, [menuList])
    
    return (
        <Box id={"menu"} sx={(theme) => ({
            mt: 5, flexGrow: 1,
            [theme.breakpoints.down('sm')]: {
                mt: 3
            }
        })}
        >
            { menu == undefined ?
                <GhostBlock anim={anim} text={'К сожалению не удалось загрузить меню'} />
                :
            menu?.MenuGroups?.length >= 1 ?
                    <Grid container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, small: 6, sm: 6, md: 6, large: 8 }}
                    >
                        {menu?.MenuGroups?.map((item, index) =>
                            <Grid className='animation-grid' xs={2} small={3} sm={2} md={2} large={2} key={index}>
                                <LinkCardMenuItem item={item} />
                            </Grid>
                        )}
                    </Grid>
                    :
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress size="md" />
                    </Stack>
            }
        </Box>
    )
}
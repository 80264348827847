
import { Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { Box, Button, Checkbox, Divider, Grid, Input, ModalClose, ModalOverflow, Radio, RadioGroup, Stack, Tab, tabClasses, TabList, TabPanel, Tabs, Typography, useTheme } from '@mui/joy';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import TollRoundedIcon from '@mui/icons-material/TollRounded';


import InputMask from 'react-input-mask';
import { useContext, useEffect, useMemo, useState } from 'react';
import ItemBasket from './ItemBasket';
import Information, { isDeliveryOpen } from '../../utils/utils_tilipad';
import { LayoutContext } from '../context';
import { generateToken, validatePhoneNumber } from '../../utils/utils';
import { TYPE_PAY_FOR_SITE, TYPE_PAY_NAL, TYPE_PAY_NONAL } from '../../utils/tilipad';




export default function ModalBasket({ modalBasket, setModalBasket, ...props }) {
    const theme = useTheme();

    const { basket, user } = useContext(LayoutContext);
    const { addBasket, removeBasket } = useContext(LayoutContext);
    const { createOrder, allCost } = useContext(LayoutContext);
    const { page_inform } = useContext(LayoutContext);

    const tg = window.Telegram.WebApp;

    const [ name, setName ] = useState(user?.name ? user?.name : '');
    const [ phone, setPhone ] = useState(user?.phone);
    const [ countPeople, setCountPeople ] = useState(1);
    const [ delivery, setDelivery ] = useState(0);
    
    const [ subtractPoints, setSubtractPoints ] = useState(false);
    const [ totalPoints, setTotalPoints ] = useState(user?.point);

    const [ btnLoad, setBtnLoad ] = useState(false);

    const [ address, setAddress ] = useState("");
    const [ street, setStreet ] = useState("");
    const [ house, setHouse ] = useState("");
    const [ apartment, setApartment ] = useState("");
    const [ entrance, setEntrance ] = useState("");
    const [ comment, setComment ] = useState("");

    // Состояние для хранения выбранного варианта оплаты
    const [payMethod, setPayMethod] = useState('nonal');

    // Обработчик изменения выбора оплаты
    const changePayMethod = (event) => {
        setPayMethod(event.target.value);
    };

    // Подставляем данные клиента
    useEffect(() => {
        setName(user?.name ? user?.name : '');
        setPhone(user?.phone ? user?.phone : '');
    }, [user]);

    useEffect(() => {
        var _de = [];
        if (street.trim() != '') _de.push(street.trim());
        if (house.trim() != '') _de.push(house.trim());
        if (apartment.trim() != '') _de.push(apartment.trim());
        if (entrance.trim() != '') _de.push(entrance.trim());
        setAddress(_de.join(', ').trim());
    }, [delivery, street, house, apartment, entrance]);

    // Обновление списка корзины
    const listBasket = useMemo(() => {
        return basket.map((item) => 
            <ItemBasket item={item} removeBasket={removeBasket} addBasket={addBasket} key={item.product.mitm_ID} />
        )
    }, [basket]);


    // Суммирование стоимости всех товаров
    const sumTotalCount = useMemo(() => {
        let summ = 0;
        basket.map((item) => summ += item.count * item.product.mitm_Price)
        if (user?.point && subtractPoints) {
            // // Проверяем вычет баллами не больше 50% от суммы корзины
            // if ((50 * summ / 100) > user?.point) {
            //     summ -= user?.point;
            //     setTotalPoints(user?.point);
            // }
            // else {
            //     setTotalPoints(50 * summ / 100);
            //     summ -= (50 * summ / 100);
            // }
            
            // Проверяем вычет баллами не больше 50% от количетства баллов
            summ -= Math.ceil(user?.point / 2);
            setTotalPoints(Math.ceil(user?.point / 2));
        }
        return summ
    }, [basket, subtractPoints, user]);

    // Создание заказа
    function orderCreate(e) {
        e.preventDefault();
        // Проверяем что можно доставиь
        if (!isDeliveryOpen(inform)) {
            tg.HapticFeedback.notificationOccurred('warning');
            alert('В данный момент мы закрыты');
            return
        }
        var trim_phone = phone.trim().replace(/\s+/g, '');
        if (name.trim() === '' || trim_phone === '') {
            tg.HapticFeedback.notificationOccurred('warning');
            alert('Вы не указали данные заказа');
            return
        };
        if (!validatePhoneNumber(trim_phone)) {
            setPhone('');
            tg.HapticFeedback.notificationOccurred('warning');
            alert('Вы указали не корректный номер телефона');
            return
        } 
        if (countPeople < 1) {
            tg.HapticFeedback.notificationOccurred('warning');
            alert('Количество персон не может быть меньше 1');
            return
        }
        setAddress([street.trim(), house.trim(), apartment.trim(), entrance.trim()].join(', ').trim());
        if (delivery & address === '' ) {
            tg.HapticFeedback.notificationOccurred('warning');
            alert('Вы не указали данные доставки');
            return
        };
        
        var _methodPay = "";
        if (payMethod == 'online')
            _methodPay = TYPE_PAY_FOR_SITE;
        else if (payMethod == 'nonal')
            _methodPay = TYPE_PAY_NONAL;
        else 
            _methodPay = TYPE_PAY_NAL;

        var now = new Date();
        // Получаем сдвиг временной зоны Екатеринбурга (UTC+5)
        const timeZoneOffset = 5 * 60; // В минутах
        // Учитываем сдвиг и преобразуем в ISO-формат
        const localDate = new Date(now.getTime() + timeZoneOffset * 60 * 1000);
        const isoFormat = localDate.toISOString().slice(0, -1);
        // const isoFormat = now.toISOString().slice(0, 19);

        console.log('-=-=-=- Create Order -=-=-=-')
        const timestamp = String(Date.now()).slice(-5);
        // const _token = generateToken(9);
        const _token = Date.now();

        const data_guest = {
            gest_DateOpen: isoFormat,
            gest_Count: countPeople.toString(),
            gest_ClientPhone: trim_phone,
            gest_Name: timestamp,
            gest_IsDelivery: delivery.toString(),
            guestDeliveries: {
                gsdlv_dlvrst_ID: 0,
                gsdlv_dlvrmt_ID: delivery == 1 ? 1 : 2, // Доставка курьером 1 ; доставка самовывозом 2
                gsdlv_pytp_ID_Prepaid: _methodPay, // Тип оплаты
                gsdlv_CashAmount: "",
                gsdlv_Date: isoFormat,
                // gsdlv_GeoRegionID: "606",
                // gsdlv_GeoCoordinates: "59.892383, 30.421824"
                // "gsdlv_PayDescription": "Банковской картой",
                gsdlv_IsAutoStart: 0,
                gsdlv_SendSooner: 0,
                gsdlv_NeedConfirmation: 1
                // "gsdlv_CookingTime": 1800,
                // "gsdlv_DeliveryTime": 1800
            },
            orders: [
                {
                    ordr_Name: timestamp,
                    orderItems: [
                        ...basket.map((itm) => ({
                            orit_mitm_ID: itm.product.mitm_ID,
                            orit_mvtp_ID: itm.product.mitm_mvtp_ID,
                            orit_Volume: 1,
                            orit_Count: itm.count.toString(),
                            orit_Price: itm.product.mitm_Price,
                            orit_VAT: 0,
                            orit_PriceVat: 0
                        })),
                    ]
                },
            ]
        }
        // Если клиент авторизован
        if (!user?.phone) {
            data_guest.gest_ClientName = name;
        }
        // Если у клиента выбрана доставка
        if (delivery) {
            data_guest.gest_ClientAddress = `<?xml version=\"1.0\"?>\n<Address>\n<Village Caption=\"Населенный пункт\" Key=\"True\">Пермь<\/Village>\n<Street Caption=\"Улица\" Key=\"True\">${street}<\/Street>\n<House Caption=\"Дом\" Key=\"True\">${house}<\/House>\n<Apartment Caption=\"Квартира\" Key=\"True\">${apartment}<\/Apartment>\n<Entrance Caption=\"Подъезд\" Key=\"True\">${entrance}<\/Entrance>\n<Comment Caption=\"Комментарий\">${comment}<\/Comment>\n<\/Address>`;
            data_guest.gest_Comment = comment;
        }
        // Если списание баллами
        if (subtractPoints) {
            data_guest.orders[0].orderItems.push(
                {
                    orit_mitm_ID: "67D0CBA2-57BD-234D-8145-12DC7E836958",
                    orit_mvtp_ID: "50C0A0B8-9403-F448-AC63-907B99BB0D14",
                    orit_Volume: totalPoints,
                    orit_Price: 0,
                    orit_Count: 1,
                    orit_VAT: 0,
                    orit_PriceVat: 0
                }
            );
        }
        // Если нет списания баллами, то начисляем привелегию
        else {
            data_guest.gest_sprv_ID = "1637B8AD-8C75-B844-9273-E68F13D66E58";
        }

        setBtnLoad(true);
        
        const redirect_url = window?.Telegram && window?.Telegram?.WebApp ? `https://t.me/tsunami_perm_bot?start=${_token}` : `http://tsunami-perm.ru?token=${_token}`
        const _data_order = {
            token: _token,
            name: name,
            redirect_url: redirect_url,
            phone: trim_phone,
            address: delivery ? address : "",
            data: JSON.stringify(data_guest),
            count_people: countPeople,
            amount: Math.round(sumTotalCount * 100) / 100,
            point: totalPoints,
            items: 
                basket.map((itm) => ({
                    mitm_ID: itm.product.mitm_ID,
                    name: itm.product.mitm_Name,
                    count: itm.count,
                    price: itm.product.mitm_Price
                }))
          }
        // Формируем заказ
        createOrder(_data_order, payMethod == 'online').then(get_result => { 
            // Заказ успешно оформлен
            if (!isEmpty(get_result)){
                if (payMethod == 'online') {
                    window.location.href = get_result?.confirmation_url;
                }
                else {
                    tg.HapticFeedback.notificationOccurred('success');
                    tg.sendData(JSON.stringify({ gest_ID: get_result.gest_ID }));
                    tg.close();
                }
                setModalBasket(false);
            } else {
                tg.HapticFeedback.notificationOccurred('error');
            }
            setBtnLoad(false);
        });
    }

    const isEmpty = (obj) => Object.keys(obj).length === 0;

    // Если корзина пустая, закрываем окно
    useEffect(() => {
        if (basket.length < 1) setModalBasket(false);
    }, [basket]);

    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);


    return (
        <Transition in={modalBasket} timeout={500}>
            {(state) => (
                <Modal
                    keepMounted
                    open={!['exited', 'exiting'].includes(state)}
                    onClose={() => setModalBasket(false)}
                    slotProps={{
                        backdrop: {
                            sx: {
                                padding: 0,
                                opacity: 0,
                                backdropFilter: 'none',
                                transition: `opacity 200ms, backdrop-filter 500ms`,
                                ...{
                                    entering: { opacity: 1, backdropFilter: 'blur(8px)' },
                                    entered: { opacity: 1, backdropFilter: 'blur(8px)' },
                                }[state],
                            },
                        },
                    }}
                    sx={{
                        visibility: state === 'exited' ? 'hidden' : 'visible',
                    }}
                >   
                    <ModalOverflow sx={{ height: 'auto' }}>
                        <ModalDialog
                            sx={{
                                [theme.breakpoints.up("lg")]: {
                                    maxWidth: '48%',
                                    mx: 0,
                                },
                                [theme.breakpoints.down("lg")]: {
                                    width: '70%',
                                    mx: 0,
                                },
                                [theme.breakpoints.down("md")]: {
                                    width: '90%',
                                    mx: 0,
                                },
                                [theme.breakpoints.down("sm")]: {
                                    width: '90%',
                                },
                                p: 0,
                                my: 5,
                                backgroundColor: 'transparent',
                                border: '0',
                                boxShadow: 'none',
                                opacity: 0,
                                transition: `opacity 200ms`,
                                ...{
                                    entering: { opacity: 1 },
                                    entered: { opacity: 1 },
                                }[state],
                            }}
                        >   
                            <ModalClose style={{ zIndex: 2 }} />
                            <Stack
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                spacing={1}
                            >
                                <Box 
                                    sx={{ 
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderRadius: 'xl'
                                    }}
                                >
                                    <Typography startDecorator={<ShoppingCartIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                        Корзина
                                    </Typography>
                                    <Divider />

                                    {listBasket}
                                </Box>
                                
                                
                                <Box 
                                    sx={{ 
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderRadius: 'xl'
                                    }}
                                >
                                    <Typography startDecorator={<SaveAltIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                        Оформление заказа
                                    </Typography>
                                    <Divider/>
                                    
                                    <form style={{ width: '100%' }}>
                                        <Stack
                                            direction={{ xs: 'column', sm: 'row', md: 'row' }}
                                            justifyContent="center"
                                            alignItems="flex-start"
                                            spacing={2}
                                            sx={{ m: 3, mb: 2 }}
                                        >
                                            <Box sx={{ width: '100%' }}>
                                                <Box>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Имя
                                                    </Typography>
                                                    <Input type="text" sx={{ borderRadius: 'md' }} placeholder="Иван" value={name} variant="outlined" onChange={(e) => setName(e.target.value)} required />
                                                </Box>

                                                <Box sx={{ mt: 2 }}>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Номер телефона
                                                    </Typography>
                                                    <InputMask mask="+7 (999) 999-99-99" value={phone} onChange={(e) => setPhone(e.target.value)}>
                                                        {(inputProps) => <Input {...inputProps} sx={{ borderRadius: 'md' }} type="tel" placeholder="+7 (000) 000-00-00" variant="outlined" disableUnderline required />}
                                                    </InputMask>
                                                </Box>

                                                <Box sx={{ mt: 2 }}>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Количество персон
                                                    </Typography>
                                                    <Input type="text" sx={{ borderRadius: 'md', maxWidth: 100 }} placeholder="1" value={countPeople} variant="outlined" onChange={(e) => setCountPeople(e.target.value)} required />
                                                </Box>
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography sx={{ mb: 0.5 }} level='title-sm' fontWeight={'xl'}>
                                                        Способ оплаты
                                                    </Typography>
                                                    <RadioGroup onChange={changePayMethod} sx={{ p: 1 }} defaultValue="nonal" orientation={{ xs: 'vertical', sm: 'horizontal', md: 'horizontal' }} name="radio-buttons-group">
                                                        <Radio value="nonal" label="Картой" color="primary" size="sm" />
                                                        <Radio value="nal" label="Наличными" color="primary" size="sm" />
                                                        <Radio value="online" label="Онлайн оплата" color="primary" size="sm" />
                                                    </RadioGroup>
                                                </Box>
                                            </Box>
                                            
                                            <Stack
                                                direction={'column'}
                                                justifyContent="center"
                                                alignItems="flex-start"
                                                spacing={2}
                                                sx={{ width: '100%', my: 2, }}
                                            >
                                                <Tabs
                                                    aria-label="tabs" 
                                                    defaultValue={0} 
                                                    sx={{ width: '100%', bgcolor: 'transparent' }}
                                                    onChange={(e, n) => setDelivery(n == 1 ? 0 : 1)}
                                                >
                                                    <TabList
                                                        disableUnderline
                                                        sx={{
                                                            width: 'min-content',
                                                            [theme.breakpoints.down('sm')]: {
                                                                width: '100%'
                                                            },
                                                            [theme.breakpoints.up('md')]: {
                                                                width: '100%'
                                                            },
                                                            p: 0.5,
                                                            gap: 0.5,
                                                            borderRadius: 'xl',
                                                            bgcolor: 'background.level1',
                                                            [`& .${tabClasses.root}[aria-selected="true"]`]: {
                                                                boxShadow: 'sm',
                                                                bgcolor: 'background.surface',
                                                            },
                                                        }}
                                                    >
                                                        <Tab disableIndicator sx={{
                                                            [theme.breakpoints.down('sm')]:{
                                                                width: '100%'
                                                            },
                                                            [theme.breakpoints.up('md')]:{
                                                                width: '100%'
                                                            },
                                                        }}>
                                                            Доставка
                                                        </Tab>
                                                        <Tab disableIndicator sx={{
                                                            [theme.breakpoints.down('sm')]:{
                                                                width: '100%'
                                                            },
                                                            [theme.breakpoints.up('md')]:{
                                                                width: '100%'
                                                            },
                                                        }}>
                                                            Самовывоз
                                                        </Tab>
                                                    </TabList>

                                                    <TabPanel value={0} sx={{ mt: 2, bgcolor: 'background.level1', borderRadius: 'xl', }}>
                                                        <Box>
                                                            <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                                Улица
                                                            </Typography>
                                                            <Input sx={{ borderRadius: 'md' }} value={street} onChange={(e) => setStreet(e.target.value)} placeholder="Карпинского" variant="outlined" required={ delivery ? true : false } />
                                                        </Box>
                                                        <Grid
                                                            container
                                                            spacing={{ xs: 1, md: 1 }}
                                                            columns={{ xs: 4, sm: 17, md: 17 }}
                                                            sx={{ width: '100%', mt: 2 }}
                                                        >
                                                            <Grid size="auto">
                                                                <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                                    Дом
                                                                </Typography>
                                                                <Input sx={{ borderRadius: 'md', width: '70px' }} value={house} onChange={(e) => setHouse(e.target.value)} placeholder="50" variant="outlined" required={ delivery ? true : false } />
                                                            </Grid>
                                                            <Grid size="auto">
                                                                <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                                    Квартира
                                                                </Typography>
                                                                <Input sx={{ borderRadius: 'md', width: '70px' }} value={apartment} onChange={(e) => setApartment(e.target.value)} placeholder="123" variant="outlined" required={ delivery ? true : false } />
                                                            </Grid>
                                                            <Grid size="auto">
                                                                <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                                    Подъезд
                                                                </Typography>
                                                                <Input sx={{ borderRadius: 'md', width: '70px' }} value={entrance} onChange={(e) => setEntrance(e.target.value)} placeholder="2" variant="outlined" required={ delivery ? true : false } />
                                                            </Grid>
                                                        </Grid>
                                                        <Box sx={{ mt: 2 }}>
                                                            <Typography sx={{ mb: 0.5 }} level="title-sm" textColor="text.secondary" fontWeight="xl">
                                                                Комментарий к заказу
                                                            </Typography>
                                                            <Input sx={{ borderRadius: 'md' }} value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Приму заказ самостоятельно" variant="outlined" required={ delivery ? true : false } />
                                                        </Box>
                                                        <Box sx={{ mt: 1 }}>
                                                            <Typography level="body-sm" fontWeight={'md'} color="danger">
                                                                * Стоимость доставки не включена в стоимость заказа
                                                            </Typography>
                                                        </Box>
                                                    </TabPanel>

                                                    <TabPanel value={1} sx={{ mt: 2, bgcolor: 'background.level1', borderRadius: 'xl', }}>
                                                        <Typography level="inherit">
                                                            Наши кафе:
                                                        </Typography>
                                                        <RadioGroup sx={{ p: 1 }} defaultValue="value1" name="radio-buttons-group">
                                                            <Radio value="value1" label={inform.address} color="primary" size="sm" />
                                                        </RadioGroup>
                                                    </TabPanel>
                                                </Tabs>
                                                { user?.point != undefined ?
                                                    <Box sx={{ m: 3 }}>
                                                        <Stack direction="row"
                                                            spacing={1}
                                                            sx={{
                                                                mx: 1,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Checkbox onChange={(e) => setSubtractPoints(e.target.checked)}/>
                                                            <Typography level="body-sm">
                                                                Списать баллами{' | '}
                                                                <Typography level="body-sm" endDecorator={<TollRoundedIcon color='primary'/>}>
                                                                    У вас: {user?.point}
                                                                </Typography>
                                                            </Typography>
                                                        </Stack>
                                                    </Box>
                                                    :
                                                    undefined
                                                }
                                            </Stack>
                                        </Stack>
                                    </form>
                                </Box>


                                { name || phone || countPeople || delivery || address || comment ?
                                    <Box 
                                        sx={{ 
                                            width: '100%',
                                            backgroundColor: 'white',
                                            borderRadius: 'xl'
                                        }}
                                    >
                                        <Typography startDecorator={<RamenDiningIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                            Заказ
                                        </Typography>
                                        <Divider />
                                        
                                        <Stack
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="stretch"
                                            spacing={0.1}
                                            sx={{ mx: 3, my: 2 }}
                                        >
                                            { name !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Имя:
                                                    </Typography>
                                                    <Typography level='body-md'>
                                                        {name}
                                                    </Typography>
                                                </Stack>
                                            : '' }

                                            { phone !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Телефон: 
                                                    </Typography>
                                                    <Typography level='body-md'>
                                                        {phone}
                                                    </Typography>
                                                </Stack>
                                            : '' }

                                            { delivery & address !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Адрес:
                                                    </Typography>
                                                    <Typography level='body-md'>
                                                        {address}
                                                    </Typography>
                                                </Stack>
                                            : '' }

                                            { delivery & comment !== '' ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Комментарий:
                                                    </Typography>
                                                    <Typography level='body-md'>
                                                        {comment}
                                                    </Typography>
                                                </Stack>
                                            : '' }

                                            <Stack
                                                sx={{ mx: 2 }}
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <Typography level='title-md' fontWeight="lg">
                                                    Количество персон: 
                                                </Typography>
                                                <Typography level='body-md' color="primary">
                                                    {countPeople}
                                                </Typography>
                                            </Stack>

                                            { subtractPoints ? 
                                                <Stack
                                                    sx={{ mx: 2 }}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    spacing={2}
                                                >
                                                    <Typography level='title-md' fontWeight='lg'>
                                                        Списание баллов:
                                                    </Typography>
                                                    <Typography color="success" level='body-md' fontWeight='lg' endDecorator={<TollRoundedIcon color='success'/>}
                                                    sx={{ px: .9, py: .4, bgcolor: 'background.level1', borderRadius: 'lg' }}>
                                                        - {totalPoints}
                                                    </Typography>
                                                </Stack>
                                            : '' }
                                        </Stack>
                                    </Box>
                                    : ''
                                }
                                
                                <Box 
                                    sx={{ 
                                        width: '100%',
                                        backgroundColor: 'white',
                                        borderRadius: 'xl'
                                    }}
                                >
                                    <Typography startDecorator={<CurrencyRubleIcon color='primary' />} fontSize='md' sx={{ px: 2, my: 1.5 }}>
                                        Стоимость заказа
                                    </Typography>
                                    <Divider />

                                    <Box sx={{ mx: 3, my: 2 }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography level='h4' fontWeight='xl'>
                                                Итого
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={0.8}
                                            >
                                                <Typography level='h3' fontWeight='xl'>
                                                    {sumTotalCount} ₽
                                                </Typography>
                                                {/* { subtractPoints && totalPoints ?
                                                    <Typography color='success' level='title-lg' fontWeight='md' endDecorator={<TollRoundedIcon color='success'/>} 
                                                    sx={{ 
                                                        '.MuiTypography-endDecorator': { ml: 0.5 },
                                                        p: 0.6, bgcolor: 'background.level1', borderRadius: 'lg'
                                                        }}>
                                                        - {totalPoints}
                                                    </Typography>
                                                    :
                                                    undefined
                                                } */}
                                            </Stack>
                                        </Stack>
                                        <Box sx={{ mt: 1 }}>
                                            <Button 
                                                sx={{ fontSize: 'lg', width: '100%' }}
                                                onClick={orderCreate}
                                                loading={btnLoad}>
                                                    <CheckCircleOutlineIcon sx={{ mr: 0.5 }}/> Заказать
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                
                            </Stack>
                        </ModalDialog>
                    </ModalOverflow>
                </Modal>
            )}
        </Transition>
    )
}

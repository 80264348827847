import { Box, Chip, CircularProgress, Container, Grid, Stack, Typography } from "@mui/joy";
import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link as LinkRouter, useParams } from "react-router-dom";
import BigCardItem from "../components/CardProductItem/BigCardItem";

import { motion } from "framer-motion";

import { LayoutContext } from "../components/context";
import GhostBlock from "../components/UI/GhostBlock";
import CircularLoad from "../components/UI/CircularLoad";
import { Helmet } from "react-helmet";



function MenuPage({ }) {
    const { slugMenu } = useParams();

    const { basket, setBasket } = useContext(LayoutContext);
    const { setObjModal } = useContext(LayoutContext);
    const { addBasket, removeBasket } = useContext(LayoutContext);
    const { menu } = useContext(LayoutContext);

    const [ anim, setAnim ] = useState(false);

    useEffect(() => {
        window.scrollTo({ 
            top: 0, 
            behavior: 'smooth' 
        });
    }, []);

    // Подгружаем товары выбранной группы
    const [ listProductsView, setListProductsView ] = useState([]);
    const { listProductALL } = useContext(LayoutContext);
    useEffect(() => {
        setListProductsView([]);
        if (listProductALL != undefined) {
            var m = []
            m = listProductALL?.filter(elem => elem?.mitm_mgrp_ID == slugMenu)
            // console.log(m);
            setListProductsView(m);
        }
        else setListProductsView(undefined);
    }, [listProductALL, slugMenu]);


    const isEmpty = (obj) => Object.keys(obj).length === 0;
    // Отслеживаем подгрузку меню и анимацию призрака
    useEffect(() => {
        if (menu != undefined) {
            if (isEmpty(menu)) setAnim(true);
            else setAnim(false);
        }
    }, [menu])

    // Смена заголовка выбранной группы
    const title = useMemo(() => {
        if (menu != undefined)
            if (!isEmpty(menu)){
                const menuItem = menu?.MenuGroups?.findIndex((element) => element.mgrp_ID === slugMenu )
                if (menuItem > -1) return menu?.MenuGroups[menuItem].mgrp_Name;
            }
        return 'Не определено';
    }, [slugMenu, menu]);

    // Микроразметка товаров
    const jsonLD = useMemo(() => {
        if (listProductALL != undefined)
            // if (menu?.MenuItems){
            return listProductALL?.filter((item) => {
                return {
                    "@context": "https://schema.org",
                    "@type": "Product",
                    "name": item?.mitm_Name,
                    "image": item?.image,
                    "description": item?.Product?.pitm_Description,
                    "sku": item?.mitm_ID,
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "RUB",
                        "price": item?.mitm_Price,
                    }
                }
            })
            // }
        return [];
    }, [slugMenu, listProductALL]);

    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Helmet>
                <title>{`Tsunami | ${title}`}</title>
                <meta name="description" content="Закажите суши, роллы, поке, wok лапшу или рис, запеченные роллы и пиццу в кафе Tsunami в Перми, ул. Карпинского 50. Самовывозом или с доставкой на дом, в офис, на праздник!" />
                <meta name="keywords" content="кафе, Пермь, ресторан, еда, напитки, десерты, паназиатская кухня, Tsunami, блюда, суши, роллы, супы, лапша, азиатская еда, wok, японские блюда, китайские блюда, рецепт" />
                
                {/* Микроразметка */}
                {jsonLD?.map((product, index) => (
                    <script
                        key={index}
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(product) }}
                    />
                ))}

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={`Tsunami | ${title}`} />
                <meta property="og:description" content="Закажите суши, роллы, поке, wok лапшу или рис, запеченные роллы и пиццу в кафе Tsunami в Перми, ул. Карпинского 50. Самовывозом или с доставкой на дом, в офис, на праздник!" />
                {/* <meta property="og:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
                <meta property="og:url" content={`http://tsunami-perm.ru/menu/${slugMenu}`} />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                {/* <meta name="twitter:card" content="summary_large_image" /> */}
                <meta name="twitter:title" content={`Tsunami | ${title}`} />
                <meta name="twitter:description" content="Закажите суши, роллы, поке, wok лапшу или рис, запеченные роллы и пиццу в кафе Tsunami в Перми, ул. Карпинского 50. Самовывозом или с доставкой на дом, в офис, на праздник!" />
                {/* <meta name="twitter:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
            </Helmet>
            <Container>
                <Typography level="h1" sx={(theme) => ({ 
                    my: 3,
                    [theme.breakpoints.down('small')]: {
                        fontSize: theme.fontSize.xl3
                    }
                    })}>
                    {title}
                </Typography>

                {menu == undefined ?
                    <GhostBlock anim={anim} text={'К сожалению не удалось загрузить меню'} />
                    :
                menu?.MenuGroups?.length >= 1 ?
                    <Box>
                        <Grid container spacing={1} sx={{ mb: 3 }}>
                            {menu?.MenuGroups?.map((itm, index) => 
                                <Grid xs='auto' key={itm.mgrp_ID}>
                                    <LinkRouter to={`/menu/${itm.mgrp_ID}`}>
                                        <Chip color="primary" onClick={function(){}} variant="plain" key={itm.mgrp_ID} size={'md'}>
                                            {itm.mgrp_Name}
                                        </Chip>
                                    </LinkRouter>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    :
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ my: 2 }}
                    >
                        <CircularProgress size="md" />
                    </Stack>
                }
                {listProductsView == undefined ?
                    <GhostBlock anim={anim} text={'К сожалению не удалось загрузить товары'} />
                    :
                listProductsView?.length >= 1 ?
                    <Grid
                        container
                        spacing={{ xs: 2, md: 2 }}
                        columns={{ xs: 1, small: 4, sm: 6, medium: 6, md: 6, large: 10, lg: 12 }}
                        sx={{ flexGrow: 1, mb: 5 }}
                    >
                        {listProductsView?.map((item, index) => 
                            <Grid xs={2} sm={3} medium={2} md={2} large={2.5} lg={3} key={item.mitm_ID}>
                                <BigCardItem
                                    setObjModal={setObjModal}
                                    product={item}
                                    basket={basket}
                                    setBasket={setBasket}
                                    addBasket={addBasket}
                                    removeBasket={removeBasket}
                                />
                            </Grid>
                        )}
                    </Grid>
                    :
                    <CircularLoad/>
                }
            </Container>
        </motion.div>
    )
}

export default MenuPage;
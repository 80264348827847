import { Box, Link, Button, DialogContent, DialogTitle, ModalClose, ModalDialog, Stack, Typography } from "@mui/joy";
import { useContext, useEffect, useRef, useState } from "react";
import Cookies from 'js-cookie';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import classes from "./DialogCode.module.css"; 
import { LayoutContext } from "../../context";
import { CheckCode } from "../../../utils/tilipad";


// Окно для авторизациия пользователя
export default function DialogCode({ state, setOpen, setStage, status, text, phone }) {
    const { user, setUser } = useContext(LayoutContext);
    const [ loading, setLoading ] = useState(false);

    // Таймер начать заново
    const [timer, setTimer] = useState(null);
    useEffect(() => {
        if (timer === 0) {
            // console.log('Скрипт запущен!');
            setTimer(null);
        }
        if (timer > 0) {
            const interval = setInterval(() => setTimer(timer - 1), 1000);
            return () => clearInterval(interval);
        }
    }, [timer]);

    useEffect(() => {
        setTimer(60);
    }, []);


    function checkUser() {
        setLoading(true);
        var trim_phone = phone?.trim().replace(/\s+/g, '');
        const _data = {
            code_phone: codeFields.join(''),
            phone: trim_phone
        }
        CheckCode(_data).then(data => {
            if (data?.user) {
                Cookies.set('u_token', data?.token, { expires: 7 });
                setOpen(false);
                setTimeout(() => {
                    setUser(data?.user);
                    setStage(status.account);
                    setOpen(true);
                }, 300);
            } 
            else alert(`Ошибка ${data?.detail}`)
            setLoading(false);
        });
    }


    const [codeFields, setCodeFields] = useState(['', '', '', '']);
    const fieldsetRef = useRef(null);
    const fieldsRef = useRef([]);

    function handleInputField(event, index) {
        const { value } = event.target;
        const newValue = value.slice(0, 1);
        event.target.value = newValue;

        // Обновляем состояние полей
        const updatedFields = [...codeFields];
        updatedFields[index] = newValue;
        setCodeFields(updatedFields);

        const step = value ? 1 : -1;
        const focusToIndex = index + step;

        if (focusToIndex >= 0 && focusToIndex < fieldsRef.current.length) {
            fieldsRef.current[focusToIndex].focus();
        }
    };


    return (
        <ModalDialog
            sx={(theme) => ({
                [theme.breakpoints.up("xl")]: {
                    width: '400px',
                    mx: 0,
                },
                [theme.breakpoints.down("xl")]: {
                    width: '30%',
                    mx: 0,
                },
                [theme.breakpoints.down("lg")]: {
                    width: '40%',
                    mx: 0,
                },
                [theme.breakpoints.down("md")]: {
                    width: '55%',
                    mx: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    width: '90%',
                    p: 3,
                },
                my: 5,
                p: 4,
                borderRadius: 'xl',
                opacity: 0,
                transition: `opacity 200ms`,
                ...{
                    entering: { opacity: 1 },
                    entered: { opacity: 1 },
                }[state],
            })}
        >
            <ModalClose style={{ zIndex: 2 }} />
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <img width={100} style={{ filter: 'grayscale(1)', opacity: '0.3' }} src="../logo.png" />
                <DialogTitle>
                    <Typography level="h2">Код доступа</Typography>
                </DialogTitle>
            </Stack>
            <DialogContent>
                <form style={{ width: '100%' }}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ mt: 1 }}>
                                <Typography sx={{ mb: 1 }} level='title-sm' fontWeight={'md'}>
                                    {text}
                                </Typography>
                                {/* <Input endDecorator={viewPwd ? <VisibilityIcon sx={{ cursor: "pointer", }} onClick={() => setViewPwd(!viewPwd)} /> : <VisibilityOffIcon sx={{ cursor: "pointer" }} onClick={() => setViewPwd(!viewPwd)} />} type={viewPwd ? "text" : "password"} sx={{ borderRadius: 'md' }} placeholder={viewPwd ? "Пароль" : "***"} variant="outlined" onChange={(e) => setPwd(e.target.value)} /> */}
                                <Box sx={{ my: 2 }}>
                                    <div className={classes.main}>
                                        <div className={classes.fieldset} ref={fieldsetRef}>
                                        {[...Array(4)].map((_, index) => (
                                            <label className={classes.box} key={index}>
                                            <input
                                                className={classes.field}
                                                type="text"
                                                placeholder="•"
                                                maxLength={1}
                                                ref={(el) => (fieldsRef.current[index] = el)}
                                                onChange={(event) => handleInputField(event, index)}
                                            />
                                            </label>
                                        ))}
                                        </div>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                        <Stack
                            direction='column'
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: '100%' }}
                        >
                            <Button
                                sx={{ fontSize: 'lg', width: '100%' }}
                                onClick={() => checkUser()}
                                loading={loading}
                            >
                                Подтвердить
                            </Button>
                            <Typography>
                                <Link disabled={timer !== null ? true : false} onClick={(e) => setStage(status.auth)}>{timer !== null ? `Повторить отправку через: ${timer} секунд` : <Typography startDecorator={<RefreshRoundedIcon/>}>Повторить отправку</Typography>}</Link>
                            </Typography>
                        </Stack>
                    </Stack>
                </form>
            </DialogContent>
        </ModalDialog>
    )
}
import React, { useState, useEffect, useMemo, useContext } from "react";
import { Box, Button, Card, Container, Divider, Grid, Link, Skeleton, Stack, Typography, useTheme } from '@mui/joy';

import DevicesIcon from '@mui/icons-material/Devices';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import PhoneIcon from '@mui/icons-material/Phone';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';

import BannerItem from "../components/MainPageUI/BannerItem/BannerItem";
import { useLocation } from "react-router-dom";
import MenuCardList from "../components/MainPageUI/MenuCardList";
import ProductListScroll from "../components/MainPageUI/ProductListScrollX";

import { motion } from "framer-motion";

// import Swiper core and required modules
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import Information from "../utils/utils_tilipad";

import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';

import { LayoutContext } from "../components/context";
import { ServerCheckInvoice } from "../utils/tilipad";
import SocialNetwork from "../components/UI/SocialNetwork";

import { Helmet } from 'react-helmet';


function MainPage({ }) {
    const { basket, setBasket } = useContext(LayoutContext);
    const { setObjModal } = useContext(LayoutContext);
    const { addBasket, removeBasket } = useContext(LayoutContext);
    const { menu, setCountBasketItem } = useContext(LayoutContext);
    const { page_inform, setOpenSnackSuccess } = useContext(LayoutContext);

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const { listProductALL } = useContext(LayoutContext);
    const [ productsInMoreBaskets, setProductsInMoreBaskets ] = useState([]);
    const [ productsInNEWs, setProductsInNEWs ] = useState([]);
    useEffect(() => {
        if (listProductALL?.length != 0) {
            if (productsInMoreBaskets.length == 0 || productsInNEWs.length == 0) {
                // console.log("AFAFAF", listProductALL);
                if (listProductALL != undefined) {
                    const basketsList = [];
                    const newsList = [];
                    for (let i = 0; i < 6; i++) {
                        basketsList.push(listProductALL[getRandomInt(listProductALL.length)]);
                        newsList.push(listProductALL[getRandomInt(listProductALL.length)]);
                    }
                    setProductsInMoreBaskets(basketsList);
                    setProductsInNEWs(newsList);
                }
                else {
                    setProductsInMoreBaskets(undefined);
                    setProductsInNEWs(undefined);
                }
            }
        }
        
    }, [listProductALL]);
    
    const banners_ads = useMemo(() => {
        if (page_inform) {
            // console.log('GGGG', page_inform.data);
            return page_inform.data.banners_ads;
        }
        else return [];
    }, [page_inform]);

    const center_banner = useMemo(() => {
        if (page_inform) {
            return { 
                title: page_inform.data.title_center_banner1,
                description: page_inform.data.description_center_banner1,
                image: page_inform.data.image_center_banner.url 
            };
        }
        else return {};
    }, [page_inform]);

    const zone_delivery = useMemo(() => {
        if (page_inform) {
            return page_inform.data.zone_delivery.url;
        }
        else return undefined;
    }, [page_inform]);


    const inform = useMemo(() => {
        if (page_inform) {
            return Information(page_inform);
        }
        else return {};
    }, [page_inform]);
    


    // -=-=- Отслеживаем токен оплаты -=-=-
    const location = useLocation();
    // Используем URLSearchParams для извлечения query-параметров
    const searchParams = new URLSearchParams(location.search);
    // Получаем конкретный параметр 'token'
    const token = searchParams.get('token');
    const isEmpty = (obj) => Object.keys(obj).length === 0;

    useEffect(() => {
        if (token) {
            ServerCheckInvoice(token).then(get_result => { 
                // Заказ успешно оформлен
                if (!isEmpty(get_result)) {
                    if (get_result?.gest_ID) setOpenSnackSuccess(true);
                }
                else alert(get_result?.detail ? get_result?.detail : "Произошла ошибка формирования заказа. Попробуйте обратиться в тех. поддержку или на горячую линию");
            });
        }
    }, [token]);



    return (
        <motion.div
            initial={{ opacity: 0, transition: '2s'  }}
            animate={{ opacity: 1, transition: '2s' }}
            exit={{ opacity: 0, transition: '3s' }}
        >
            <Helmet>
                <title>Кафе Цунами – Суши, роллы, wok, поке и пицца с доставкой в Перми</title>
                <meta name="description" content="Закажите суши, роллы, поке, wok лапшу или рис, запеченные роллы и пиццу в кафе Tsunami в Перми, ул. Карпинского 50. Самовывозом или с доставкой на дом, в офис, на праздник!" />
                <meta name="keywords" content="кафе, Пермь, ресторан, еда, напитки, десерты, паназиатская кухня, Tsunami, меню, суши, роллы, лапша, супы, блюда, wok, японская кухня, китайская кухня, доставка еды" />
                
                {/* Микроразметка */}
                <script type="application/ld+json">
                    {JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "http://tsunami-perm.ru/",
                            "logo": "http://tsunami-perm.ru/favicon.ico",
                            "name": "Tsunami Пермь"
                        }
                    )}
                </script>


                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="Кафе Цунами – Суши, роллы, wok, поке и пицца с доставкой в Перми" />
                <meta property="og:description" content="Закажите суши, роллы, поке, wok лапшу или рис, запеченные роллы и пиццу в кафе Tsunami в Перми, ул. Карпинского 50. Самовывозом или с доставкой на дом, в офис, на праздник!" />
                {/* <meta property="og:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
                <meta property="og:url" content="http://tsunami-perm.ru/" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                {/* <meta name="twitter:card" content="summary_large_image" /> */}
                <meta name="twitter:title" content="Кафе Цунами – Суши, роллы, wok, поке и пицца с доставкой в Перми" />
                <meta name="twitter:description" content="Закажите суши, роллы, поке, wok лапшу или рис, запеченные роллы и пиццу в кафе Tsunami в Перми, ул. Карпинского 50. Самовывозом или с доставкой на дом, в офис, на праздник!" />
                {/* <meta name="twitter:image" content="https://yourcafe.com/images/cafe-interior.jpg" /> */}
            </Helmet>
            <Container
                sx={(theme) => ({
                    position: 'relative', py: 5,
                    [theme.breakpoints.down('sm')]: {
                        py: 3
                    }
                })}
            >
                {/* Блок быстрой рекламы */}
                {banners_ads != undefined ?
                    <Swiper
                            modules={[Autoplay, Pagination, A11y]}
                            spaceBetween={20}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 4000, pauseOnMouseEnter: true }}
                        >
                            {banners_ads.map((itm, index) => 
                                    itm.view ? 
                                    <SwiperSlide key={index}>
                                        {
                                            itm?.link?.url ?
                                            <Link href={itm?.link?.url} underline="none">
                                                <BannerItem 
                                                    title={itm.title} 
                                                    text={itm.description}
                                                    image={itm.image.url}
                                                    button={
                                                        <Button sx={{ mt: 1 }} endDecorator={<OpenInNewRoundedIcon/>} component="a" href={itm?.link?.url} color="primary" size="md">
                                                            Перейти
                                                        </Button>
                                                    }/>
                                            </Link>
                                            :
                                            <BannerItem 
                                                title={itm.title} 
                                                text={itm.description}
                                                image={itm.image.url}/>
                                        }
                                    </SwiperSlide>
                                    :
                                    undefined
                                )}
                    </Swiper>
                    :
                    <Card variant="plain" sx={{ borderRadius: 'xl', justifyContent: 'center', minHeight: '300px', display: 'flex', gap: 2 }}>
                        <Typography sx={{ width: '50%' }}>
                            <Skeleton loading={true} sx={{ display: 'block' }}>
                                Текст Скилет
                            </Skeleton>
                        </Typography>
                        <Typography sx={{ width: '60%' }}>
                            <Skeleton loading={true} sx={{ display: 'block' }}>
                                Текст Скилет
                            </Skeleton>
                        </Typography>
                    </Card>
                    }
                
                {/* Блок шагов */}
                <Box 
                    className={"box-steps"}
                    sx={(theme) => ({
                        backgroundColor: 'white',
                        borderRadius: '15px', 
                        p: 5, mt: banners_ads != undefined ? 5 : 0,
                        display: 'block',
                        [theme.breakpoints.down('md')]: {
                            display: 'none'
                        }
                    })}
                >
                    <Grid container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ flexGrow: 1 }}
                        columns={{ xs: 2, sm: 8, md: 12 }}
                        spacing={{ xs: 1, sm: 2 }}
                    >
                        <Grid xs={2} sm={2} md>
                            <Typography 
                                startDecorator={<DevicesIcon color="primary" fontSize="large" />}
                                level="h2"
                                sx={{ fontFamily: 'Nunito' }}
                            >
                                Заказываете
                            </Typography>
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Через сайт или нашего Telegram-бота <Link href="https://t.me/tsunami_perm_bot" sx={{ color: '#C30013', textDecorationColor: '#C30013' }}>@tsunami_perm_bot</Link>
                            </Typography>
                        </Grid>
                        <Grid xs={2} sm={1} md={0.6}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <img className={"arrow"} src="./arrow_right.png" style={{ margin: 10 }}/>
                            </Stack>
                        </Grid>
                        <Grid xs={2} sm={2} md>
                            <Typography 
                                startDecorator={<LocalFireDepartmentIcon color="primary" fontSize="large" />} 
                                level="h2"
                                sx={{ fontFamily: 'Nunito' }}
                            >
                                Готовим
                            </Typography>
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Заказ готовится в течении <Typography sx={{ color: '#C30013' }}>40 мин</Typography>. За это время можно собрать пазлы
                            </Typography>
                        </Grid>
                        <Grid xs={2} sm={1} md={0.6}>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <img className={"arrow"} src="./arrow_right.png" style={{margin: 10}}/>
                            </Stack>
                        </Grid>
                        <Grid xs={2} sm={2} md>
                            <Typography 
                                startDecorator={<DeliveryDiningIcon color="primary" fontSize="large" />} 
                                level="h2"
                                sx={{ fontFamily: 'Nunito' }}
                            >
                                Доставляем
                            </Typography>    
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Наш курьер едет к вам чтобы отдать заказ еще <Typography sx={{ color: '#C30013' }}>горячим</Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                
                {/* Блок Меню */}
                <MenuCardList menuList={menu} page_inform={page_inform} />
                
                {/* Блок Товаров которые есть в корзинах */}
                <Box 
                    sx={(theme) => ({
                        mt: 5, flexGrow: 1,
                        [theme.breakpoints.down('sm')]: {
                            mt: 3
                        }
                    })}
                >
                    <Typography 
                        level="h2" 
                        sx={{ 
                            fontFamily: 'Nunito', 
                            fontWeight: 'bold', 
                            mb: 2
                            }}
                    >
                        У многих в корзине
                    </Typography>

                    <ProductListScroll 
                        productList={productsInMoreBaskets} 
                        basket={basket}
                        setBasket={setBasket}

                        setObjModal={setObjModal}
                        setCountBasketItem={setCountBasketItem}
                        addBasket={addBasket}
                        removeBasket={removeBasket}
                    />
                </Box>
                
                {/* Блок рекламы */}
                <Box
                    id={'cafe'}
                    variant="plain"
                    sx={(theme) => ({
                        mt: 5, flexGrow: 1,
                        [theme.breakpoints.down('sm')]: {
                            mt: 3
                        }
                    })}
                >
                    <BannerItem 
                        title={center_banner.title} 
                        text={center_banner.description} 
                        // button={
                        //     <Button color="neutral">
                        //         Где вы находитесь?
                        //     </Button>
                        // } 
                        image={center_banner.image}/>
                </Box>
                    
                {/* Блок товаров-новинок */}
                <Box 
                    id={"new"}
                    sx={(theme) => ({
                        mt: 5,
                        [theme.breakpoints.down('sm')]: {
                            mt: 3
                        }
                    })}
                >
                    <Typography sx={{fontFamily: 'Nunito', fontWeight: 'bold', mb: 2}} level="h2">
                        Новинки!
                    </Typography>

                    <ProductListScroll 
                        productList={productsInNEWs} 
                        basket={basket}
                        setBasket={setBasket}
                        
                        setObjModal={setObjModal}
                        setCountBasketItem={setCountBasketItem}
                        addBasket={addBasket}
                        removeBasket={removeBasket}
                    />
                </Box>
            </Container>
            
            {/* Блок контактов и зоны доставки */}
            <Box
                sx={(theme) => ({
                    py: 5, mt: 2, mb: 5, backgroundColor: "#fff",
                    [theme.breakpoints.down('sm')]: {
                        mt: 3,
                        py: 3
                    }
                })}
            >
                <Container>
                    <Stack
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={{ sm: 3, md: 5 }}
                        direction={{ xs: 'column', md: 'row' }}
                    >
                        <Box id={"delivery"} sx={{ mt: 2, position: "relative", overflow: "hidden", minWidth: '50%', width: { xs: '100%', md: '60%' } }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography level="h2">
                                    Доставка
                                </Typography>
                                <Typography level="title-lg">
                                    Стоимость доставки:
                                </Typography>
                                <Typography level="body-lg">
                                    Зеленая зона (быстрая) - 200 руб. (заказ от 2000 рублей - доставка бесплатно)
                                </Typography>
                                <Typography level="body-lg">
                                    Желтая зона (средняя) - 350 руб. (заказ от 3500 рублей - доставка бесплатно)
                                </Typography>
                                <Typography level="body-lg">
                                    Красная зона (общая) - 500 руб. (заказ от 5000 рублей - доставка бесплатно)
                                </Typography>
                            </Box>
                            <Typography sx={{ width: '100%', mb: 3 }} level="h4">
                                Зона доставки
                            </Typography>
                            <a href="https://yandex.ru/maps/50/perm/?utm_medium=mapframe&utm_source=maps" style={{color: "#eee", fontSize: 12, position: "absolute", top: 0 }}>
                                
                            </a>
                            <iframe src={zone_delivery} height={"100%"} width={"100%"} style={{ borderRadius: '15px', border: "none", position: "relative", minHeight: '400px', minWidth: '50%' }}>
                            </iframe>
                        </Box>

                        <Box id={"contacts"} sx={{ width: { xs: '100% !important', md: 'auto' } }}>
                            <Box sx={{ mb: 2 }}>
                                <Typography level="h2">
                                    Самовывоз
                                </Typography>
                                <Typography level="h4" color="danger">
                                    От любой суммы
                                </Typography>
                                <Typography level="body-lg">
                                    Оплата заказа при получении
                                </Typography>
                            </Box>
                            <Divider/>
                            <Typography sx={{ mt: 2, width: '100%', fontWeight: 'bold', mb: 3 }} level="h4">
                                Контакты
                            </Typography>
                            
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.phone ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <PhoneIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Link
                                                href={`tel:${inform.phone}`}
                                                fontWeight={'lg'} 
                                                color="neutral"
                                                sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                    {inform.phone}
                                            </Link>
                                        </Stack>
                                    </Skeleton>
                                </Box>

                                {/* <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.email ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <AlternateEmailIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Link
                                                href={`mailto:${inform.email}`}
                                                fontWeight={'lg'} 
                                                color="neutral"
                                                sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                    {inform.email}
                                            </Link>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box> */}

                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.address ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <LocationOnIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }}>
                                                {inform.address}
                                            </Typography>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box>

                                <Box sx={{ display: 'flex', position: 'relative', height: '30px', minWidth: '200px' }}>
                                    <Skeleton sx={{ width: 'auto', height: 'auto' }} loading={ inform.work_time ? false : true }>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <HistoryToggleOffIcon color="primary" sx={{ fontSize: 30 }}/>
                                            <Typography sx={{ fontFamily: 'Nunito', fontSize: 20, fontWeight: 'bold' }} lineHeight={1}>
                                                {inform.work_time}
                                            </Typography>
                                            
                                        </Stack>
                                    </Skeleton>
                                </Box>
                                <Divider/>
                                <Box sx={{ mt: 2 }}>
                                    <SocialNetwork page_inform={page_inform}/>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
            </Box>


            {/* <Container sx={{ my: 7 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={3}
                >
                    <Box>
                        <Box sx={{ p: 3, backgroundColor: 'white', borderRadius: '15px' }}>
                            <Typography sx={{ fontFamily: 'Nunito', color: '#C30013' }} level="h2">
                                Куда вы можете доставить?
                            </Typography>
                            <Typography sx={{fontFamily: 'Nunito'}}>
                                Через сайт или нашего Telegram-бота <Link color="danger" href="https://t.me/tsunami_perm_bot">@tsunami_perm_bot</Link>
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ position: "relative", overflow: "hidden", minWidth: '50%' }}>
                        <img src={"./cafe.jpg"} style={{ borderRadius: '15px' }} width={"100%"} />
                    </Box>
                </Stack>
            </Container> */}
        </motion.div>
    )
}

export default MainPage;